import React from 'react';
import AuthLayout from '@/layouts/AuthLayout';
import LoginForm from '@/components/composite/auth/LoginForm';
import { signIn } from 'next-auth/react';
const LoginPage: React.FC = () => {
  return <AuthLayout title="Welcome back" data-sentry-element="AuthLayout" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx">
      <LoginForm session={null} data-sentry-element="LoginForm" data-sentry-source-file="login.tsx" />
      {/* this is only here to test the athena oauth flow while its being built out */}
      {/* <button style={{ outline: '1px solid black', marginTop: '1rem' }} onClick={() => signIn('athena')}>
        Sign in with Athena
       </button> */}
    </AuthLayout>;
};
export default LoginPage;