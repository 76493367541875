import { Callout as RadixCallout } from '@radix-ui/themes';

// https://www.radix-ui.com/themes/docs/components/callout

export type CalloutColor = 'gray' | 'red' | 'green' | 'yellow' | 'blue' | 'indigo' | 'purple' | 'pink' | 'cyan' | 'orange' | 'crimson';
interface CalloutProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'soft' | 'surface' | 'outline';
  color?: CalloutColor;
  size?: '1' | '2' | '3';
  highContrast?: boolean;
  calloutTextWrapper?: boolean;
  calloutTextSize?: '1' | '2' | '3';
  ref?: React.RefObject<HTMLDivElement>;
}
const Callout = ({
  children,
  icon,
  calloutTextWrapper,
  calloutTextSize,
  ref,
  ...props
}: CalloutProps) => {
  return <RadixCallout.Root ref={ref} {...props} data-sentry-element="unknown" data-sentry-component="Callout" data-sentry-source-file="Callout.tsx">
      {icon && <RadixCallout.Icon>{icon}</RadixCallout.Icon>}
      {calloutTextWrapper ? <RadixCallout.Text size={calloutTextSize}>{children}</RadixCallout.Text> : children}
    </RadixCallout.Root>;
};
Callout.displayName = 'Callout';
export default Callout;