import { forwardRef } from 'react';
import { IconButton, TextField } from '@radix-ui/themes';

// https://www.radix-ui.com/themes/docs/components/text-field

interface InputProps {
  label?: string;
  id?: string;
  color?: 'gray' | 'red' | 'green' | 'yellow' | 'blue' | 'indigo' | 'purple' | 'pink' | 'indigo' | 'cyan' | 'orange' | 'crimson';
  type?: 'number' | 'search' | 'time' | 'text' | 'hidden' | 'date' | 'datetime-local' | 'email' | 'month' | 'password' | 'tel' | 'url' | 'week';
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  radius?: 'small' | 'medium' | 'large' | 'full' | 'none';
  variant?: 'surface' | 'soft' | 'classic';
  size?: '1' | '2' | '3';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  iconRightClick?: () => void;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  className?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  textColor?: string;
  isError?: boolean;
}
const Input = forwardRef<HTMLInputElement, InputProps>(({
  onChange,
  iconRight,
  iconRightClick,
  iconLeft,
  textColor,
  color,
  isError = false,
  ...props
}, ref) => {
  return <TextField.Root ref={ref} required={props.required} onChange={event => onChange && onChange(event)} color={isError ? 'red' : color} style={{
    color: isError ? 'var(--red-11)' : color
  }} {...props}>
        {(iconLeft || iconRight) && <TextField.Slot>{iconLeft}</TextField.Slot>}
        {iconRight && <TextField.Slot>
            <IconButton size="1" variant="ghost" onClick={iconRightClick} type="button">
              {iconRight}
            </IconButton>
          </TextField.Slot>}
      </TextField.Root>;
});
Input.displayName = 'Input';
export default Input;