import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { Link as RadixLink, Grid, Flex, Box } from '@radix-ui/themes';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/Form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ROUTES from '@/constants/routes';
import { EyeClosedIcon, EyeOpenIcon } from '@radix-ui/react-icons';
import Input from '@/components/radix/Input';
import Button from '@/components/radix/Button';
const formSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  password: z.string().min(1, 'Password is required')
});
const LoginForm = () => {
  const router = useRouter();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const {
    status
  } = useSession();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoggingIn(true);
    const response = await signIn('credentials', {
      email: values.email,
      password: values.password,
      redirect: false
    });
    console.log('response', response);
    if (response?.error) {
      form.setError('root', {
        message: 'Incorrect email or password'
      });
      setIsLoggingIn(false);
    } else {
      router.push(ROUTES.DASHBOARD);
    }
  };
  return <Form {...form} data-sentry-element="Form" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Grid gap="4" data-sentry-element="Grid" data-sentry-source-file="LoginForm.tsx">
          <FormField control={form.control} name="email" render={({
          field,
          fieldState
        }) => <FormItem>
                <Grid gap="2">
                  <label htmlFor="email" style={{
              color: fieldState.error ? 'var(--red-11)' : undefined
            }}>
                    Email
                  </label>
                  <FormControl>
                    <Input size="3" id="email" type="email" placeholder="Enter your email" required isError={fieldState.invalid} {...field} />
                  </FormControl>
                  <FormMessage type="error" />
                </Grid>
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="LoginForm.tsx" />

          <FormField control={form.control} name="password" render={({
          field,
          fieldState
        }) => <FormItem>
                <Grid gap="2">
                  <Flex justify="between" align="center">
                    <label htmlFor="password" style={{
                color: fieldState.error ? 'var(--red-11)' : undefined
              }}>
                      Password
                    </label>
                    <RadixLink asChild size="2">
                      <Link href={ROUTES.AUTH.FORGOT_PASSWORD}>Forgot password?</Link>
                    </RadixLink>
                  </Flex>
                  <FormControl>
                    <Input size="3" id="password" type={showPassword ? 'text' : 'password'} placeholder="Enter your password" required iconRight={!showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />} iconRightClick={() => setShowPassword(!showPassword)} isError={fieldState.invalid} {...field} />
                  </FormControl>
                  <FormMessage type="error" />
                </Grid>
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="LoginForm.tsx" />
        </Grid>

        <Flex mt="4" gap="4" justify="end" align="center" data-sentry-element="Flex" data-sentry-source-file="LoginForm.tsx">
          <RadixLink asChild size="3" data-sentry-element="RadixLink" data-sentry-source-file="LoginForm.tsx">
            <Link href={ROUTES.AUTH.SIGNUP} data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">Create account</Link>
          </RadixLink>
          <Button type="submit" size="3" loading={status === 'loading' || isLoggingIn} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
            Sign in
          </Button>
        </Flex>
        {form.formState.errors.root && <Box mt="2">
            <FormMessage type="error" className="w-full">
              {form.formState.errors.root.message}
            </FormMessage>
          </Box>}
      </form>
    </Form>;
};
export default LoginForm;