const ROUTES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    NEW_PASSWORD: '/auth/new-password',
  },
  DASHBOARD: '/dashboard',
  REPORTS: '/reports',
};

export const API_ROUTES = {
  AUTH: '/api/auth',
  PUBLIC: '/api/public',
  API_DOC: '/api-doc',
};

export default ROUTES;
