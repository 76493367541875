import Image from 'next/image';
interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}
const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
  subtitle
}) => {
  return <div className="w-full min-h-screen bg-teal-700 flex items-center justify-center" data-sentry-component="AuthLayout" data-sentry-source-file="AuthLayout.tsx">
      <div className="w-full max-w-6xl lg:grid lg:grid-cols-2 gap-8 p-4 lg:p-8">
        <div className="bg-white rounded-2xl overflow-hidden flex items-start justify-center p-8">
          <div className="w-full max-w-md">
            <Image src="/images/login_brand.png" alt="Logo" width={131} height={100} className="mb-2" data-sentry-element="Image" data-sentry-source-file="AuthLayout.tsx" />
            <div className="flex justify-center w-full">
              <div className="grid w-full mt-10">
                <h1 className="text-3xl text-start">{title}</h1>
                <p className="text-balance text-muted-foreground mb-4">{subtitle}</p>
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center">
          <Image src="/images/login_pic.png" alt="Login image" width={1920} height={1080} className="max-w-full max-h-full object-contain" priority data-sentry-element="Image" data-sentry-source-file="AuthLayout.tsx" />
        </div>
      </div>
    </div>;
};
export default AuthLayout;