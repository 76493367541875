import { forwardRef } from 'react';
import { cn } from '@/lib/utils';
import { Button as RadixButton, IconButton as RadixIconButton, ButtonProps as RadixButtonProps } from '@radix-ui/themes';

// https://www.radix-ui.com/themes/docs/components/button

interface ButtonProps extends RadixButtonProps {
  children: React.ReactNode;
  isIcon?: boolean;
  className?: string;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  isIcon,
  children,
  ...props
}, ref) => {
  if (isIcon) {
    return <RadixIconButton {...props} color={props.color as any} className={cn(props.className, 'cursor-pointer')} ref={ref}>
        {children}
      </RadixIconButton>;
  }
  return <RadixButton {...props} className={cn(props.className, 'cursor-pointer')} ref={ref}>
      {children}
    </RadixButton>;
});
Button.displayName = 'Button';
export default Button;